@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

* {
  font-family: "Manrope", sans-serif;
}

html {
  scroll-behavior: smooth;
}

nav {
  height: 12.5vh;
  z-index: 1;
  background-color: white;
}

.custom-bg {

}

.custom-bg::before {

}

.five-rem {
  font-size: 5.25rem;
  line-height: 1;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 70px;
  /* Adjust the gap between items as needed */
  padding: 50px 80px;
  /* Optional: Add padding around the grid */
}

.grid-item {
  padding: 30px 40px;
  /* Adjust padding inside each item as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Box shadow for each item */
  border-radius: 8px;
  /* Optional: Add border radius for rounded corners */
  text-align: center;
  /* Center the text inside each item */
}

.custom-box-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}